var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"add-post"},[_c('h1',[_vm._v("Edytuj subskrybcje")]),_c('el-form',{ref:"subscription",staticClass:"add-post-form",attrs:{"model":_vm.subscription,"label-width":"100px"}},[_c('el-form-item',{attrs:{"label":"Nazwa","prop":"name","rules":[
        { required: true, message: 'Nazwa jest wymagana'},
      ]}},[_c('el-input',{attrs:{"autocomplete":"off"},model:{value:(_vm.subscription.name),callback:function ($$v) {_vm.$set(_vm.subscription, "name", $$v)},expression:"subscription.name"}})],1),_c('el-form-item',{attrs:{"label":"Treść","prop":"description","rules":[
        { required: true, message: 'Treść jest wymagana'},
      ]}},[_c('el-input',{attrs:{"type":"textarea"},model:{value:(_vm.subscription.description),callback:function ($$v) {_vm.$set(_vm.subscription, "description", $$v)},expression:"subscription.description"}})],1),_c('el-form-item',{attrs:{"label":"Cena","prop":"price","rules":[
        { required: true, message: 'Cena jest wymagana'},
      ]}},[_c('el-input',{attrs:{"autocomplete":"off"},model:{value:(_vm.subscription.price),callback:function ($$v) {_vm.$set(_vm.subscription, "price", $$v)},expression:"subscription.price"}})],1),_c('el-form-item',{attrs:{"label":"Czas trwania","prop":"duration","rules":[
        { required: true, message: 'Czas trwania jest wymagany'},
      ]}},[_c('el-input',{attrs:{"autocomplete":"off"},model:{value:(_vm.subscription.duration),callback:function ($$v) {_vm.$set(_vm.subscription, "duration", $$v)},expression:"subscription.duration"}})],1),_c('el-form-item',{attrs:{"label":"Ilosć darmowych odświeżeń","prop":"number_of_raises","rules":[
        { required: true, message: 'Ilosć darmowych odświeżeń jest wymagana'},
      ]}},[_c('el-input',{attrs:{"autocomplete":"off"},model:{value:(_vm.subscription.number_of_refreshes),callback:function ($$v) {_vm.$set(_vm.subscription, "number_of_refreshes", $$v)},expression:"subscription.number_of_refreshes"}})],1),_c('el-form-item',{attrs:{"label":"Cena odświeżenia","prop":"refresh_price","rules":[
        { required: true, message: 'Cena odświeżenia jest wymagana'},
      ]}},[_c('el-input',{attrs:{"autocomplete":"off"},model:{value:(_vm.subscription.refresh_price),callback:function ($$v) {_vm.$set(_vm.subscription, "refresh_price", $$v)},expression:"subscription.refresh_price"}})],1),_c('el-form-item',{attrs:{"label":"Ilosć darmowych podbić","prop":"number_of_raises","rules":[
        { required: true, message: 'Ilosć darmowych podbić jest wymagana'},
      ]}},[_c('el-input',{attrs:{"autocomplete":"off"},model:{value:(_vm.subscription.number_of_raises),callback:function ($$v) {_vm.$set(_vm.subscription, "number_of_raises", $$v)},expression:"subscription.number_of_raises"}})],1),_c('el-form-item',{attrs:{"label":"Cena podbicia","prop":"raise_price","rules":[
        { required: true, message: 'Cena podbicia jest wymagana'},
      ]}},[_c('el-input',{attrs:{"autocomplete":"off"},model:{value:(_vm.subscription.raise_price),callback:function ($$v) {_vm.$set(_vm.subscription, "raise_price", $$v)},expression:"subscription.raise_price"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.submitForm()}}},[_vm._v("Zapisz")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }